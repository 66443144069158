import { NextComponentType } from 'next'
import Head from 'next/head'
import styled from 'styled-components'
import { S3_ROOT } from 'src/lib/constants/s3'
import { getLiff } from 'src/lib/page-directory/liff'
import { MainButton } from 'src/ui/page-directory/Button'

const NotFoundPage: NextComponentType = (props) => {
  return (
    <StyledComponent />
  )
}

const NotFoundComponent: React.FCX = (props) => {
  return (
    <>
      <Head>
        <title>お探しのページは見つかりませんでした</title>
      </Head>
      <div className={`${props.className}`}>
        <img src={`${S3_ROOT}/yadokarrikun_bikkuri.png`} alt="404" />
        <h1>お探しのページは<br />見つかりませんでした</h1>
        <div>
          <MainButton onClick={async () => {
            const liff = await getLiff()
            liff.closeWindow()
          }}>閉じる</MainButton>
        </div>
      </div>
    </>
  )
}

const StyledComponent = styled(NotFoundComponent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  h1 {
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 40px;
  }
  
  img {
    max-width: 320px;
  }
  
  button {
    width: 240px;
  }
`

export default NotFoundPage
